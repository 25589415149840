.project {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
  border-left: 5px solid white;
  animation: slideIn 0.5s ease-in-out;
}

.project-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.project-icon {
  font-size: 1.6rem;
  margin-right: 0.5rem;
  color: #2193b0;
}

.project:hover {
  background-color: #f0f0f0;
  transform: translateX(20px);
  border-left: 5px solid #2c3e50;
  .project-description {
    color: #666;
  }
}

.project-title {
  font-size: 1.5rem;
  color: #333;
  transition: all 0.8s ease;
  position: relative;
}

.project-title::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2193b0;
  transition: width 0.5s linear;
}

.project:hover .project-title {
  color: #2193b0;
}

.project:hover .project-title::after {
  width: 100%;
}

.project-description {
  font-size: 1.01rem;
  color: #ffffff;
  margin-top: 0.5rem;
}

.project-time {
  font-size: 0.8rem;
  color: #333;
  margin-top: 0.5rem;
}

.project-link {
  text-decoration: none;
  font-size: 0.8rem;
  color: #333;
  margin-top: 0.5rem;
}
.project-link a{
  text-decoration: none;
  font-size: 0.8rem;
  color: #2c3e50;
  margin-top: 0.5rem;
}
.project-link a:hover{
  text-decoration: underline;
  font-size: 0.8rem;
  color: black;
  margin-top: 0.5rem;
}



.project-skills {
  font-size: 0.9rem;
  color: #333;
  margin-top: 0.5rem;
}

.project-skills ul {
  padding-left: 1.25rem;
}

@keyframes slideIn {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}