.card-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .card-front,
  .card-back {
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2193b0;
    color: #fff;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .card-back {
    background-color: #6dd5ed;
  }
  
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
    }
  }