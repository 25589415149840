html, body {
  height: 100%;
}

.footer {
  background-color: #2c3e50;
  padding: 1rem 0;
  color: #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.social-list li {
  margin-right: 2rem;
}

.social-list a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  transition: color 0.3s ease-in-out;
}

.social-list a:hover {
  color: #ddd;
  text-decoration: none;
}

.social-list a svg {
  margin-right: 4px;
  transition: transform 0.3s ease-in-out;
}

.social-list a:hover svg {
  transform: rotate(20deg);
}