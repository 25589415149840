@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 1rem;
  background-color: #2c3e50;
  height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

.nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.logo-link {
  text-decoration: none;
  color: #fff;
  position: relative;
  font-family: 'Pacifico', cursive;
}

.logo-link:hover {
  color: #ccc;
}

.logo-link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #fff;
  transition: width 0.8s ease-in-out;
}

.logo-link:hover::before {
  width: 100%;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  font-family: 'Roboto', sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 1.1rem;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  transition: all 0.5s ease-in-out;
  margin-left: 4rem;
}

.nav-link:hover {
  color: #ccc;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  transform: translateY(-6px);
}

.nav-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::before {
  transform: scaleX(1);
}

.nav-icon {
  margin-right: 0.5rem;
}

.desktop-nav {
  display: flex;
}

.burger-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  outline: none;
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
  z-index: 110;
}

.menu-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #2c3e50;
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: auto;
}

.nav-link {
  margin-bottom: 0.5rem;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0, 0, 0, 0.5); */
  z-index: 5;
  pointer-events: none;
}

.mobile-nav {
  display: none;
}

.open .menu-container {
  transform: translateX(0);
}
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    display: block;
  }

  .burger-button {
    display: block;
  }
}