@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

.projects {
  position: relative;
  padding: 2rem 0;
  font-family: 'Poppins', sans-serif;
  background-image: linear-gradient(135deg, #2c3e50, #6dd5ed, #2193b0);
}

.projects h2 {
  font-size: 2.3rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
  animation: fadeIn 1s ease-in;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}